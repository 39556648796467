import PropTypes from 'prop-types';

import { length } from 'stringz';

export const CharacterCounter = ({ text }) => {
  // NOTE: (toot.cat) shortcut our hack for counting up
  return <span className='character-counter'>{length(text)}</span>;
};

CharacterCounter.propTypes = {
  text: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
};
